.hero {
  background-image: url("../../assets/Home-Winter.JPG");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  position: relative;
}

.hero-content {
  position: relative;
  z-index: 1;
  padding: 2rem;
}
.blur-border {
  position: relative;
  z-index: 1;
  transition: transform 0.3s ease;
}

.blur-border::before {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1;
  background: inherit;
  filter: blur(10px);
  opacity: 0.7;
  transition: all 0.3s ease;
}

.blur-border:hover::before {
  background-color: rgba(62, 99, 138, 0.5);
  filter: blur(20px);
  opacity: 0.9;
}
