.sticky {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10; /* Ensure the header stays on top of content */
    background-color: rgb(255, 255, 255); /* Adjust background color as needed */
    padding: 1rem; /* Adjust padding as needed */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
    transition: all 0.2s ease-in-out; /* Smooth transition on scroll */
  }
  
  nav.sticky.scrolled {
    background-color: #f5f5f5f3; /* Change background color on scroll (optional) */
  }
  