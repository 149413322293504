@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities{
    .top-items{
        height: 99vh;
    }
}
html{
    scroll-behavior: smooth;
}