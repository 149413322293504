.modal {
  display: none; /* Initially hidden */
  position: fixed; /* Stay in place */
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: #b13939;
  margin: 15% auto; /* 15% from top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be wider */
  z-index: 100;
}

.modal-button {
  background-color: #4CAF50; /* Green color */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer; /* Indicate clickable button */
}
.scroll {
  margin: 4px, 4px;
  padding: 4px;
  background-color: green;
  width: 500px;
  height: 110px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
}
.rounded-image {
  border-radius: 10px;
}